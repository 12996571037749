<template>
  <div>
    <h4 v-if="isNaN(role.id) && show_title">Moficar Rol</h4>
    <h4 v-else-if="show_title">Moficar Rol</h4>
    <div class="form-container">
      <b-form-group
        label="Nombre"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.role.position_name.$model"
          :state="validateState('position_name')"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Grupo"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-select
          id="name-select"
          name="name-select"
          v-model="$v.role.position.$model"
          :options="Groups"
          value-field="id"
          text-field="name"
          :disabled="!isNaN(role.id)"
          :state="validateState('position')"
          aria-describedby="select-name-live-feedback"
        ></b-form-select>

        <b-form-invalid-feedback id="input-name-live-feedback"
          >Debe seleccionar un tipo de permiso.</b-form-invalid-feedback
        >
      </b-form-group>
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(this.role.id)"
          class="col"
          style="text-align: left"
        >
          <b-button class="mr-1" size="sm" variant="danger" @click="deleteRole"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "RoleForm",
  mixins: [validationMixin],
  props: {
    Role: {
      type: Object,
    },
    Groups: {
      type: Array,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      role: this.Role
        ? {
            id: this.Role.id,
            position_name: this.Role.position_name,
            position: this.Role.position,
            institution: this.Role.school,
          }
        : {
            id: generateUniqueId(),
            position_name: "",
            position: null,
            institution: this.$store.getters.getInstitutionId,
          },
      selected_position: null,
      // role_positions: [],
    };
  },
  validations: {
    role: {
      position_name: {
        required,
        minLength: minLength(3),
      },
      position: {
        required,
      },
    },
  },
  computed: {},
  methods: {
    // fetchRolePositions() {
    //   this.$restful.Get(`/authentication/group/`).then((response) => {
    //     this.role_positions = response;
    //   });
    // },
    validateState(key) {
      const { $dirty, $error } = this.$v.role[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.role.$touch();
      if (this.$v.role.$anyError) {
        return;
      }
      if (isNaN(this.role.id)) this.createRole();
      else this.updateRole();
    },
    createRole() {
      this.$restful.Post(`/teaching/roles/`, this.role).then((response) => {
        this.$emit("created", response);
        toast("Rol creado.");
      });
    },
    updateRole() {
      this.$restful
        .Put(`/teaching/roles/${this.role.id}/`, this.role)
        .then((response) => {
          this.role = response;
          toast("Rol actualizado.");
          this.$emit("updated", response);
        });
    },
    deleteRole() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Rol?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful.Put(`/teaching/roles/${this.role.id}`);
          this.$emit("deleted", this.role.id);
        }
      });
    },
  },
  created() {
    // this.fetchRolePositions();
  },
};
</script>

<style scoped>
</style>